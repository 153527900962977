<template>
  <div class="main-content">
    <!-- 头 -->
    <div class="top" :style="{backgroundColor:isSkin}">
      <div class="thumb-title">样书申请</div>
    </div>
    <div class="title-top">
      <div class="ts-xx">图书信息</div>
      <div class="sq-sj">申请时间</div>
      <div class="sq-zt">申请状态</div>
      <div class="wl-xx">物流信息</div>
    </div>
    <div class="list">
      <applyList v-for="(item,index) in list" :key="index" :item="item"></applyList>
      <div class="page" v-show="pageShow">
        <div class="page1">
          <el-pagination background :current-page="currentPage"
                  :page-size="1" layout="prev, pager, next" :total="totals" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import applyList from './box/applyList-box'
export default {
  components:{applyList},
  data() {
    return {
      pageShow:false,
      totals:1,
      currentPage:1,
      pageSize:10,
      list:[]
    }
  },
  computed:{
    isSkin(){
		  return this.$store.state.isSkin
    },
  },
  created(){
    this.handleCurrentChange(1)
    this.getUserBooksApply()
  },
  methods:{
    /**样书列表**/
    getUserBooksApply(){
      var that = this
      that.$api.getUserBooksApply({
        params:{
          pageNum:that.currentPage,
          pageSize:that.pageSize
        }
      }).then(res=>{
        if (res.data.code == 0) {
          that.list = res.data.data.list
          that.totals = res.data.data.pages
          if (res.data.data.list.length == 0){
            that.pageShow = false
          }else {
            that.pageShow = true
          }
        }else{
          that.list = []
          that.pageShow = false
        }
      })
    }, 
    /**当前页按钮**/ 
    handleCurrentChange(index) {
      console.log(index);
      this.currentPage = index;
      this.getUserBooksApply()
      this.$nextTick(function () {
        /**更换分页按钮皮肤**/
        var array = document.querySelectorAll(".el-pagination.is-background .el-pager li"); 
        if(array && array.length>0){
          array.forEach((item,index1) => {
            if (index == array[index1].innerText) {
              array[index1].style.background  = this.isSkin;
            }else{
              array[index1].style.background  = "#f4f4f5";
            }
          })
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
.main-content{
  width: 100%;
  .top{
    width: 100%;
    height: 55px;
    border-radius: 4px;
    line-height: 55px;
    .thumb-title{
      font-size: 18px;
      color: #FFFFFF;
      margin-left: 12px;
    }
  }
  .title-top{
    width: 100%;
    height: 40px;
    margin: 20px 0px 0px 0px;
    display: flex;
    justify-items: center;
    align-items: center;
    font-size: 14px;
    line-height: 40px;
    color: #666666;
    .ts-xx{
      margin-left: 111px;
    }
    .sq-sj{
      margin-left: 206px;
    }
    .sq-zt{
      margin-left: 120px;
    }
    .wl-xx{
      margin-left: 150px;
    }
  }
  .list{
    width: 100%;
    .page{
      width: 896px;
      padding: 40px 0px 10px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .page1{
        display: flex;
        justify-items: center;
        align-items: center;
      }
    }
  }
}
</style>