<template>
  <div>
      <div class="content">
          <div class="one">
              <div class="Img">
                <img :src="item.booksImg" alt="">
              </div>
              <div class="title">
                <div class="title-title">{{'《'+item.booksName+'》'}}</div>
                <div class="title-content">{{item.booksAuthor}}</div>
                <div class="title-price">{{'¥'+item.booksPrice}}</div>
              </div>
          </div>
          <div class="time">{{item.createDate.split(' ')[0]}}</div>
          <div class="shenhe1" v-if="item.status==1">审核通过</div>
          <div class="shenhe2" v-if="item.status==0">审核中</div>
          <div class="shenhe2" v-if="item.status==2">已发货</div>
          <div class="shenhe3" v-if="item.status==3">
              <div>审核失败</div>
              <div class="sh-img">
                  <img src="../../../../assets/users/sbai.png" alt="">
              </div>
          </div>
          <div class="wl-view">
              <div>{{item.logistics}}</div>
              <div class="iconfont icon-copy">{{item.logisticsNumber}}</div>
              <div class="order" v-show="item.logisticsNumber != null" v-clipboard:copy="item.logisticsNumber" v-clipboard:success="onCopy" v-clipboard:error="onError">复制物流单号</div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        },
    },
    methods:{
        onCopy (e) {
            this.$message.success("内容已复制到剪切板！")
        },
        // 复制失败时的回调函数
        onError (e) {
            this.$message.error("抱歉，复制失败！")
        }
    }
}
</script>

<style scoped lang="scss">
.content{
    width: 100%;
    height: 130px;
    background: #FCFCFC;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    margin-top: 20px;
    display: flex;
    justify-items: center;
    align-items: center;
    .one{
        width: 248px;
        height: 90px;
        display: flex;
        justify-items: center;
        align-items: center;
        margin-left: 20px;
        .Img{
            display: flex;
            justify-items: center;
            align-items: center;
            width: 90px;
            height: 90px;
            > img{
                width: 100%;
                height: 100%;
            }
        }
        .title{
            margin-left: 10px;
            .title-title{
                font-size: 16px;
                line-height: 22px;
                color: #333333;
                margin-top: 3px;
            }
            .title-content{
                font-size: 12px;
                line-height: 17px;
                color: #999999;
                margin-top: 10px;
            }
            .title-price{
                font-size: 20px;
                line-height: 28px;
                color: #FE2929;
                margin-top: 10px;
            }
        }
    }
    .time{
        width: 127px;
        height: 30px;
        font-size: 14px;
        line-height: 30px;
        color: #333333;
        margin-left: 70px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .shenhe1{
        width: 80px;
        height: 30px;
        font-size: 14px;
        line-height: 30px;
        color: #333333;
        text-align: center;
        margin-left: 75px;
    }
    .shenhe2{
        width: 80px;
        height: 30px;
        font-size: 14px;
        line-height: 30px;
        color: #333333;
        text-align: center;
        margin-left: 75px;
    }
    .shenhe3{
        width: 80px;
        height: 30px;
        font-size: 14px;
        line-height: 30px;
        color: #333333;
        display: flex;
        justify-items: center;
        align-items: center;
        text-align: center;
        margin-left: 75px;
        .sh-img{
            width: 14px;
            height: 14px;
            margin-left: 5px;
            margin-top: -12px;
        }
    }
    .wl-view{
        width: 190px;
        height: 60px;
        margin-left: 70px;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #333333;
        .order{
            cursor: pointer;
        }
    }
}
</style>