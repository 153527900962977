import { render, staticRenderFns } from "./applyList-box.vue?vue&type=template&id=2e96529e&scoped=true&"
import script from "./applyList-box.vue?vue&type=script&lang=js&"
export * from "./applyList-box.vue?vue&type=script&lang=js&"
import style0 from "./applyList-box.vue?vue&type=style&index=0&id=2e96529e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e96529e",
  null
  
)

export default component.exports